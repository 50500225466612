@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --primary: 206 100% 63.9%;
    --primary-foreground: 210 40% 98%;
    --warning: 38 92% 50%;
     --warning-foreground: 48 96% 89%;
     --muted: 210 40% 96.1%;
     --muted-foreground: 215.4 16.3% 46.9%;
     --accent: 210 40% 96.1%;
     --accent-foreground: 222.2 47.4% 11.2%;
     --input: 214.3 31.8% 91.4%;
     --border: 214.3 31.8% 91.4%;



}
html {
  scroll-behavior: smooth;
}
/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
} */
.center {
  @apply flex justify-center items-center;
}

.text-5-and-half-xl {
  font-size: 3.5rem;
  line-height: 1;
}

.play-button-shadow {
  filter: drop-shadow(0px 4px 4px rgba(245, 71, 72, 0.5));
}


.text-xxs {
  font-size: 0.65rem;
  line-height: 0.875rem;
}

.swiper-slide:last-child {
  margin-right: 10rem;
}


.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.scrollbar-hidden {
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}


/* scrollbar styles */
/* Global CSS or component CSS */
.scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: #c3c9d4 #f1f5f9;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #d0d4de;
  border-radius: 9999px;
  border: 2px solid #f1f5f9;
}


/* Styles for vertical slider on homepage */

/* .swiper-container {
  width: 80%;
  position: relative;
  margin: 0 auto;
} */

/* .swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
} */



/* css for loading ui for product list */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 350px 0;
  }
}

.shimmer{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, transparent,   rgba(229, 225, 225, 0.872), transparent);
  background-size: 200% 200%;
  animation: shimmer 3s infinite;
}


/* horizontal stepper */
.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step::after {
  content: '';
  position: absolute;
  top: 25%;
  left: 50%;
  height: 2px;
  width: 100%;
  background-color: #D1D5DB; /* Tailwind's gray-300 */
  z-index: 1;
}

.step:last-child::after {
  display: none;
}
.step-completed::after {
  background-color: #10B981; /* Tailwind's green-500 */
}



/* styles for delivery and total charges on order detail page  */
.line::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  height: 1px;
  width: calc(100% - 2rem); /* Adjust based on padding/margin */
  border-bottom: 1px dotted gray;
  transform: translateY(-50%);
}

.line {
  padding-right: 1rem; /* Adjust based on spacing needed */
}
